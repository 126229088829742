<template>
  <v-dialog
    v-model="computedShowConfirmPopUp"
    width="clamp(30%, 400px, 100%)"
  >
    <v-card>
      <v-card-title>{{$t('t.PromptConfirmation')}}</v-card-title>
      <v-card-text class="d-flex-flex-column">
        <div class="text-body-1">
          <span class="mr-1">{{$t('t.ConfirmationExportMessage')}}</span>
          <v-chip color="secondary">{{itemsCount}}</v-chip>
        </div>
        <v-form
          v-model="formIsValid"
          ref="form"
        >
          <v-text-field
            :label="$t('t.Filename')"
            v-model="fileName"
            :rules="[rules.required, rules.counter]"
            class="mt-4"
            counter="50"
          />
          <div class="d-flex flex-row gap mt-4">
            <v-select
              :items="availableFieldCharacters"
              :label="$t('t.FieldDelimiter')"
              v-model="fieldDelimiter"
            />
            <v-select
              :items="availableDecimalCharacters"
              :label="$t('t.DecimalDelimiter')"
              v-model="decimalDelimiter"
            />
            <v-select
              :items="availableDateFormats"
              :label="$t('t.DateFormat')"
              item-text="name"
              item-value="value"
              v-model="dateFormat"
            />
          </div>

        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          data-cy="export-excel-confirm"
          :disabled="!formIsValid"
          @click="downloadCSV()"
        >
          {{$t('t.Yes')}}
        </v-btn>
        <v-btn
          data-cy="export-excel-cancel"
          color="primary"
          @click="computedShowConfirmPopUp = false"
        >{{$t('t.No')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import fileDownload from 'js-file-download'
import Search from '../controllers'

export default {
  data () {
    return {
      availableDateFormats: [{ name: 'YYYY-mm-dd', value: '%Y-%m-%d' }, { name: 'YYYY/mm/dd', value: '%Y/%m/%d' }, { name: 'dd-mm-YYYY', value: '%d-%m-%Y' }, { name: 'dd/mm/YYYY', value: '%d/%m/%Y' }, { name: 'mm-dd-YYYY', value: '%m-%d-%Y' }, { name: 'mm/dd/YYYY', value: '%m/%d/%Y' }],
      formIsValid: false,
      showConfirmPopUp: false,
      rules: {
        required: value => !!value || this.$t('t.IsRequired'),
        counter: value => value.length <= 50
      }
    }
  },
  methods: {
    async downloadCSV () {
      new Search(Search.actions.EXPORT_CSV)
        .setMainSearch(this.search)
        .execute()
        .then(data => fileDownload(data, this.fileName + '.csv'))
        .finally(this.computedShowConfirmPopUp = false)
    }
  },
  computed: {
    availableDecimalCharacters () {
      if (this.fieldDelimiter === ',') {
        return ['.']
      }
      return [',', '.']
    },
    availableFieldCharacters () {
      if (this.decimalDelimiter === ',') {
        return [';', '|']
      }
      return [',', ';', '|']
    },
    computedShowConfirmPopUp: {
      get () {
        return this.showConfirmPopUp
      },
      set (v) {
        this.showConfirmPopUp = v
        this.$emit('input', v)
      }
    },
    fieldDelimiter: {
      get () {
        return this.$store.getters.currentUser.exportSettings?.fieldDelimiter
      },
      set (value) {
        this.$store.dispatch('setCurrentUser', { exportSettings: { decimalDelimiter: this.decimalDelimiter, fieldDelimiter: value, dateFormat: this.dateFormat, fileName: this.fileName } })
      }
    },
    fileName: {
      get () {
        return this.$store.getters.currentUser.exportSettings?.fileName
      },
      set (value) {
        this.$store.dispatch('setCurrentUser', { exportSettings: { decimalDelimiter: this.decimalDelimiter, fieldDelimiter: this.fieldDelimiter, dateFormat: this.dateFormat, fileName: value } })
      }
    },
    decimalDelimiter: {
      get () {
        return this.$store.getters.currentUser.exportSettings?.decimalDelimiter
      },
      set (value) {
        this.$store.dispatch('setCurrentUser', { exportSettings: { decimalDelimiter: value, fieldDelimiter: this.fieldDelimiter, dateFormat: this.dateFormat, fileName: this.fileName } })
      }
    },
    dateFormat: {
      get () {
        return this.$store.getters.currentUser.exportSettings?.dateFormat
      },
      set (value) {
        this.$store.dispatch('setCurrentUser', { exportSettings: { decimalDelimiter: this.decimalDelimiter, fieldDelimiter: this.fieldDelimiter, dateFormat: value, fileName: this.fileName } })
      }
    },
    itemsCount () {
      return this.search?.selectedItems?.size.total !== 0 ? this.search?.selectedItems?.size.total : this.search.resultCount
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (v) {
        this.computedShowConfirmPopUp = v
      }
    }
  },
  props: {
    value: Boolean,
    search: Search
  }
}
</script>

<style lang="stylus" scoped></style>
